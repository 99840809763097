import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

  const pathname = window.location.pathname;

  useEffect(() => {

    let appName: string = "Default";

    switch (window.location.hostname) {
      case "teamleader.bitstyle.de":
        appName = "Teamleader";
        break;
      case "admin.bitstyle.de":
        appName = "Admin";
        break;
      case "yoga.bitstyle.de":
        appName = "Yoga";
        break;
    }

    document.title = appName + " - OfCourse.com";

    setTimeout(() => {
      const link = document.createElement("link");
      link.setAttribute("rel", "manifest");
      link.setAttribute("href", "/manifest.json?appName=" + encodeURIComponent(appName));
      document.head.appendChild(link);
    }, 2000)
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {pathname}
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
